<!-- 新闻中心 -->
<template>
  <div class="intoHuadu-container">
    <navSecondPageVue type="NewsCenter" v-if="isResultPage"></navSecondPageVue>
    <Tabs :routerProps="routerProps" v-if="showTab && isResultPage"></Tabs>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" @hideTab="hideTab"></component>
      </keep-alive>
    </router-view>
  </div>
</template>
<script setup>
import { ref, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import navSecondPageVue from '../components/navSecondPage.vue'
import Tabs from '../components/Tabs.vue'
const routerProps = [
  {
    name: '集团新闻',
    path: '/NewsCenter/News'
  },

  {
    name: '员工天地',
    path: '/NewsCenter/Staff'
  }
]
const showTab = ref(true)
function hideTab() {
  showTab.value = false
}
const route = useRoute()

watch(
  () => route.fullPath,
  () => {
    // hideTab: true
    if (route.meta.hideTab) {
      showTab.value = false
    } else {
      showTab.value = true
    }
  }
)

const isResultPage = computed(() => {
  // 路由meta 定义是搜索结果列表 就不显示二级导航
  if (route.meta.isSearchPage) {
    return false
  } else {
    return true
  }
})
</script>
<style lang="stylus" scoped></style>
